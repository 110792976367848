$o-container--max-width: map-get($base__breakpoint, l) !default;
$o-container--padding-s: space(3/4);
$o-container--padding-l: space--x();

@mixin o-container {
    @if $o-container--max-width {
        @include center(false);
        max-width: $o-container--max-width;
        @include media(old-ie) {
            min-width: $o-container--max-width;
        }
    }
    
    padding: {
        right: $o-container--padding-s;
        left: $o-container--padding-s;
    }
    
    @include media(m-up) {
        padding: {
            right: $o-container--padding-l;
            left: $o-container--padding-l;
        }
    }
    
    &--bleed {
        padding: {
            right: 0;
            left: 0;
        }
    }
}

.o-container {
    @include o-container();
}

@include media-generate(".o-container__bleed", $media-only: s) {
    margin: {
        right: -$o-container--padding-s;
        left: -$o-container--padding-s;
    }
    @include media(m-up) {
        margin: {
            right: -$o-container--padding-l;
            left: -$o-container--padding-l;
        }
    }
}