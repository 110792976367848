$o-slider--overflow-s: space(3);
$o-slider--overflow-m: space(6);
$o-slider--overflow-l: space--h(6);
$o-slider--scrollbar-size: 0;

.o-slider {
    display: grid;
    grid-auto-columns: calc(100% - #{$o-slider--overflow-s});
    grid-auto-flow: column;
    grid-gap: space--s(1.5);

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    @if ($o-slider--scrollbar-size > 0) {
        margin-bottom: -$o-slider--scrollbar-size;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: $o-slider--scrollbar-size;
            
            &-thumb {
                background-color: rgba(0, 0, 0, .5);
                border-radius: 3.5px;
                box-shadow: 0 0 1px rgba(255, 255, 255, .5);
            }
        }
    }
    
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(100%);

    @include media('m') {
        grid-auto-columns: calc(100% - #{$o-slider--overflow-m});
    }
    @include media('l-up') {
        grid-auto-columns: calc(100% - #{$o-slider--overflow-l});
        grid-gap: space(2);
    }

    // Toggle the code below when https://bugs.chromium.org/p/chromium/issues/detail?id=719908 is fixed 
    // (will also need to tweak width and margin on the @media print code on .o-slider__item below
    @media print {
        display: block;
    }
    // @media print {
    //     grid-template-columns: repeat(2, 1fr);
    //     grid-auto-columns: unset;
    //     grid-auto-flow: unset;
    // }
}

// [1] Remove when https://bugs.chromium.org/p/chromium/issues/detail?id=719908 is fixed
.o-slider__item {
    scroll-snap-align: center;

    @media print {
        width: calc(50% - #{space() / 2}); // [1]
        margin-bottom: space(); // [1]
        page-break-inside: avoid;
        display: inline-block !important;
    }
}

:root.has-js {
    @include media('m-to') {
        .o-slider__item:not(.is-active) {
            display: initial;
        }
    }
    @include media('l-up') {
        .o-slider {
            grid-gap: 0;
            grid-auto-columns: 100%;
            
            overflow: hidden;
            position: relative;

            @if ($o-slider--scrollbar-size > 0) {
                margin-bottom: 0;
            }
        }
    }
}
