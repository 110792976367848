.configurator__label {
    cursor: pointer;

    input {
        margin-top: space--t(1/2);
    }
}
.configurator__images {
    position: relative;
}
.configurator__image {
    position: absolute;
        left: 0;
        top: 0;
    transition: opacity 350ms;
    
    &:not(.is-active) {
        opacity: 0;
    }
}
.configurator__image__caption {
    padding: {
        right: space--s();
        left: space--s();
    }
    position: absolute;
        bottom: 0;
        width: 100%;
}
