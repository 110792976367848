.logo {
    display: block;
    // @include media(s) {
    //     height: 70px * 160px / 190px;
    //     width: 160px;
    // }
    // @include media(m-to) {
    //     margin: {
    //         left: auto;
    //         right: auto;
    //     }
    // }
}