@media screen {
    .curtain {
        overflow: hidden;
        will-change: height;
        
        &.is-hidden {
            display: none;
        }
    }
    .curtain:not(.is-transitioned) {
        height: 0;
    }
    .curtain.has-transitions {
        transition: height 250ms;
    }
    .curtain:focus {
        outline: none;
    }
    .curtain__icon {
        transition: transform 250ms;
    }
    .curtain__head.is-active .curtain__icon {
        transform: rotate(45deg);
    }
    .curtain__head.is-active .curtain__icon--mirror {
        transform: rotate(180deg);
    }
}
@media print {
    .curtain__icon {
        display: none;
    }
}
