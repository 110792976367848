@viewport {
    zoom: 1.0;
    width: extend-to-zoom;
}

// @import url("//fast.fonts.net/t/1.css?apiType=css&projectid=41921328-27bb-4e3f-94f5-ab28f5b63668");
@font-face {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    src: url("/fonts/aafdd021-88d4-41d8-8e15-fe91f83e4b17.woff2") format("woff2"),
         url("/fonts/81d44d4b-ac07-4ad4-8cdd-4df771a3c1c3.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    src: url("/fonts/28351402-8fc5-4d21-a3df-e99d2d814bd4.woff2") format("woff2"),
         url("/fonts/10cee8c3-6e4c-4f57-8121-cf5e361423a7.woff") format("woff");
    font-weight: 400;
    font-style: oblique;
}
@font-face {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    src: url("/fonts/f7552419-a773-41ab-ae4a-b12b1d853f02.woff2") format("woff2"),
         url("/fonts/83e72918-97c3-41cd-8a7d-4056788a00f0.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    src: url("/fonts/e28dd043-42c3-47cb-815e-32648d6a8a2a.woff2") format("woff2"),
         url("/fonts/97eeb983-cbcb-40c4-b724-919d44b8d4af.woff") format("woff");
    font-weight: 500;
    font-style: oblique;
}
@font-face {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    src: url("/fonts/848602e3-bda3-4b1c-976b-fe26eecfab31.woff2") format("woff2"),
         url("/fonts/e4eacf8d-aba1-40d4-9597-1198e91dcf00.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    src: url("/fonts/2acd9263-c166-4d81-97ca-7318c4753945.woff2") format("woff2"),
         url("/fonts/bbf607bd-4426-47a9-ae87-c843830b7c4a.woff") format("woff");
    font-weight: 700;
    font-style: oblique;
}


//**
//* Global variables
//*/

// font and spacing

$base__font-size: 17px !default;
$base__font-size--small: 14px !default;
$base__font-size--large: 20px !default;
$base__line-height: 24px !default;
$base__line-height--small: 20px !default;
$base__line-height--large: 28px !default;
$base__space-unit: 20px !default;
$base__font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base__font-family--head: $base__font-family !default;
$base__font-weights: ('normal': normal, 'medium': 500, 'bold': bold) !default;

// colours

$base__colors: (
    'white': #FFFFFF,
    'lily-white': #EBEBEB,
    'light-gray': #DDDDDD,
    'mine-shaft': #333333,
    'black': #000000,
    
    'fall': #EE2964,
    'foliage': #BD2737,
    'summer': #FAA61A,
    'sunshine': #F58220,
    'winter': #00BBE7,
    'ice': #0091B8,
    'spring': #9ECC3B,
    'grass': #41AD49,
) !default;

$base__colors--overlay: (
    'white': rgba(black, .1),
    'iron': rgba(black, .2),
    'magnesium': rgba(black, .3),
    'steel': rgba(black, .6),
    'mine-shaft': rgba(black, .8),
) !default;

$base__colors--veillay: (
    'black': rgba(white, .1),
    'mine-shaft': rgba(white, .2),
    'steel': rgba(white, .4),
    'iron': rgba(white, .8),
    'white': rgba(white, .9),
) !default;

$base__color: #333333 !default;
$base__color--head: $base__color !default;
$base__color--link: $base__color !default;
$base__color--focus: map-get($base__colors, light-gray) !default;
$base__background-color: #FFFFFF !default;

// breakpoints

// webflow.com breakpoints
// $base__breakpoint: (
//     s: 479px,
//     m: 767px,
//     l: 991px,
//     x: null,
// ) !default;

$base__breakpoint: (
    s: 500px,
    m: 850px,
    l: 1300px,
    x: null,
) !default;

// NOTE: a breakpoint with true as a second value means the style will be included but not wrapped in any media query

$base__breakpoints: (
    s:    '(max-width: ' + (map-get($base__breakpoint, s)) + ')',
    m-up: '(min-width: ' + (map-get($base__breakpoint, s) + 1) + ')',
    m-to: '(max-width: ' + (map-get($base__breakpoint, m)) + ')',
    m:    '(min-width: ' + (map-get($base__breakpoint, s) + 1) + ') and (max-width: ' + (map-get($base__breakpoint, m)) + ')',
    l-up: '(min-width: ' + (map-get($base__breakpoint, m) + 1) + ')',
    l-to: '(max-width: ' + (map-get($base__breakpoint, l)) + ')',
    l:    '(min-width: ' + (map-get($base__breakpoint, m) + 1) + ') and (max-width: ' + (map-get($base__breakpoint, l)) + ')',
    x:    '(min-width: ' + (map-get($base__breakpoint, l) + 1) + ')',
    
    h-m:  '(min-height: ' + (map-get($base__breakpoint, s) + 1) + ')',
    h-l:  '(min-height: ' + (map-get($base__breakpoint, m) + 1) + ')',
    h-x:  '(min-height: ' + (map-get($base__breakpoint, l) + 1) + ')'
) !default;