.hero {
    background: {
        color: map-get($base__colors, 'black');
        position: left top, center top;
        size: auto, cover;
    }
    padding-top: space();
    
    @include media(l-up) {
        padding: {
            bottom: space--h(2);
            top: space--h();
        }
    }
    @include media(h-x) {
        padding: {
            bottom: space--h(2, space--x(2));
            top: space--h($addend: space--h());
        }
    }
}

// .hero {
//     overflow: hidden;
//     position: relative;
// }
// .hero__bg {
//     bottom: 0;
//     height: auto;
//     left: 50%;
//     min-height: 100%;
//     min-width: 100%;
//     position: absolute;
//     right: 0;
//     top: 0;
//     transform: translateX(-50%);
//     width: auto;
//     z-index: layer(background);
// }
// .hero__bg {
//     left: 0;
//     height: 100%;
//     object: {
//         fit: cover;
//         position: center top;
//     }
//     position: absolute;
//     top: 0;
//     width: 100%;
//     z-index: layer(background);
// }