.nav-link {
    border-bottom: 2px solid transparent;
    font-weight: map-get($base__font-weights, 'normal');
    text-transform: uppercase;
    
    &,
    &:hover,
    &:focus {
        color: map-get($base__colors, 'white');
    }
    &:hover,
    &:focus {
        border-bottom-color: map-get($base__colors, 'white');
    }
    
    @include media(m-to) {
        font-size: small;
    }
}
@include media-generate('.nav-link--button') {
    background-color: map-get($base__colors--veillay, 'mine-shaft');
    display: inline-block;
    padding: space--s(2/3) space--s() space--s(2/3, -2);
    transition: {
        duration: 250ms;
        property: background-color, border-bottom-color;
    }
    
    &.is-active {
        background-color: map-get($base__colors--overlay, 'iron');
    }
}



// .nav-icon {
//     @include reset(button);
//     
//     color: map-get($base__colors, 'white');
// }
// .nav-icon__icon {
//     display: block;
//     fill: currentColor;
//     
//     @include media(l-up) {
//         height: 1em;
//         width: 1em;
//     }
// }