@media screen {
    .tabs__body:not(.is-active) {
        display: none;
    }
}
.tabs__body:focus {
    outline: none;
}
@media print {
    // [1] required because Chrome would get confused about layout
    .tabs__body {
        display: block; // [1]
        page-break-inside: avoid;
    }
}
