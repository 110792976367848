body {
    @include typo--body(m);
    text-rendering: optimizeLegibility;
    
    color: $base__color;
    background-color: $base__background-color;
    
    @if $base__font-family != sans-serif {
        font-family: $base__font-family;
    }
    @if map-get($base__font-weights, normal) != normal {
        font-weight: map-get($base__font-weights, normal);
    }
    
    // Disabling overflow hidden as it is causing issues with the iframe
    // html.has-js & {
    //     overflow: hidden; // this website is embedded via iframe with height set to match the height of the content
    // }
}
