$separator__border: 3px solid map-get($base__colors, 'black');

.separator {
    @include reset(hr);
    
    &--top {
        border-top: $separator__border;
    }
    &--btm {
        border-bottom: $separator__border;
    }
    @if variable-exists(base__colors) {
        &--c- {
            @each $name, $color in $base__colors {
                &#{$name} {
                    border-color: $color;
                }
            }
        }
    }
}